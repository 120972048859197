<section class="p-3 p-md-4 p-xl-5">
  <div class="container">
    <div class="card border-light-subtle shadow-sm">
      <div class="row g-0">
        <div class="col-12 col-md-6 text-bg-primary">
          <div class="d-flex align-items-center justify-content-center h-100">
            <div class="col-10 col-xl-8 py-3" >
              <img class="img-fluid rounded mb-4" loading="lazy" src="assets/img/vinyasalogobig.jpeg" width="245" height="245" alt="Vinyasa Logo">
              <hr class="border-primary-subtle mb-4">
              <h2 class="h1 mb-4">Download Your Latest 10 BE Receipt</h2>
              <p class="lead m-0">A Smile Some love And a Little Contribution.</p>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="card-body p-3 p-md-4 p-xl-5">
            <div class="row">
              <div class="col-12">
                <div class="mb-5">
                  <h3>Enter the Details</h3>
                </div>
              </div>
            </div>
            <form >
              <div class="row gy-3 gy-md-4 overflow-hidden">
                <div class="col-12">
                  <label for="name" class="form-label">Donar Name:<span class="text-danger">*</span></label>
                  <input type="text" class="form-control" name="name" id="name" [(ngModel)]="name" placeholder="Donar Name" required>
                </div>
                <div class="col-12">
                  <label for="pan" class="form-label">PAN Card No:<span class="text-danger">*</span></label>
                  <input type="text" class="form-control" name="pan" id="pan" placeholder="PAN Card No" [(ngModel)]="pan" value="" required>
                </div>
                <div class="col-12">

                </div>
                <div class="col-12">
                  <div class="d-grid">
                    <button class="btn bsb-btn-xl btn-primary" type="submit" (click)="submit()">Download Now</button>
                  </div>
                </div>
              </div>
            </form>
            <div class="row">

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
