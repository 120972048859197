<!-- ======= Hero Section ======= -->
<section id="hero">
  <div id="heroCarousel" class="carousel slide carousel-fade" data-ride="carousel">

    <div class="carousel-inner" role="listbox">

      <!-- Slide 1 -->
      <div *ngIf="this.cmsdata.banner.length > 0" class="carousel-item active" style="background-image: url({{this.cmsdata.banner[0].image}});">
        <div class="container">
          <div class="carousel-content animated fadeInUp">
            <h2>Welcome to <span>Vinyasa Trust</span></h2>
            <p>Vinyasa means special attention or special way, and Vinyasa Trust was formed to ensure the welfare of the differently challenged underprivileged students of Karnataka. </p>
            <div class="text-center"><a routerLink="/donate" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" class="btn-get-started">Donate</a></div>
          </div>
        </div>
      </div>

      <div *ngIf="this.cmsdata.banner.length === 0" class="carousel-item active" style="background-image: url(assets/img/slide/slide123.png);">
        <div class="container">
          <div class="carousel-content animated fadeInUp">
            <h2>Welcome to <span>Vinyasa Trust</span></h2>
            <p>Vinyasa means special attention or special way, and Vinyasa Trust was formed to ensure the welfare of the differently challenged underprivileged students of Karnataka. </p>
            <div class="text-center"><a routerLink="/donate" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" class="btn-get-started">Donate</a></div>
          </div>
        </div>
      </div>

      <!-- Slide 2 -->
      <div *ngIf="this.cmsdata.banner.length > 1" class="carousel-item" style="background-image: url({{this.cmsdata.banner[1].image}});">
        <div class="container">
          <div class="carousel-content animated fadeInUp">
            <h2>Empowering Through Knowledge </h2>
            <p>End-to end support to encourage students to pursue higher education</p>
            <div class="text-center"><a routerLink="/education" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" class="btn-get-started">Vidya Vinyasa</a></div>
          </div>
        </div>
      </div>
      <div *ngIf="this.cmsdata.banner.length === 0"  class="carousel-item" style="background-image: url(assets/img/slide/Education2.jpg);">
        <div class="container">
          <div class="carousel-content animated fadeInUp">
            <h2>Empowering Through Knowledge </h2>
            <p>End-to end support to encouragestudents to pursue higher education</p>
            <div class="text-center"><a routerLink="/education" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" class="btn-get-started">Vidya Vinyasa</a></div>
          </div>
        </div>
      </div>

      <!-- Slide 3 -->
      <div *ngIf="this.cmsdata.banner.length > 2" class="carousel-item" style="background-image: url({{this.cmsdata.banner[2].image}});">
        <div class="container">
          <div class="carousel-content animated fadeInUp">
            <h2>Nurturing the Athletic Side</h2>
            <p>Compete and win in various sporting events</p>
            <div class="text-center"><a routerLink="/sports" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" class="btn-get-started">Kreeda Vinyasa</a></div>
          </div>
        </div>
      </div>

      <div *ngIf="this.cmsdata.banner.length === 0" class="carousel-item" style="background-image: url(assets/img/slide/Sports1.jpg);">
        <div class="container">
          <div class="carousel-content animated fadeInUp">
            <h2>Nurturing the Athletic Side</h2>
            <p>Compete and win in various sporting events</p>
            <div class="text-center"><a routerLink="/sports" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" class="btn-get-started">Kreeda Vinyasa</a></div>
          </div>
        </div>
      </div>

    </div>

    <a class="carousel-control-prev" href="#heroCarousel" role="button" data-slide="prev">
      <span class="carousel-control-prev-icon bx bx-left-arrow" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>

    <a class="carousel-control-next" href="#heroCarousel" role="button" data-slide="next">
      <span class="carousel-control-next-icon bx bx-right-arrow" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>

    <ol class="carousel-indicators" id="hero-carousel-indicators"></ol>

  </div>
</section><!-- End Hero -->



<main id="main">

  <!-- ======= Cta Section ======= -->
  <!-- <section id="cta" class="cta">
    <div class="container">

      <div class="row">
        <div class="col-lg-12 text-center text-lg-left">
          <h3>Donations are exempt from tax as per
            <span>section 80G & 12A</span> of Indian Income Tax Act <br> 80G Order Number: <span>ITBA/EXM/S/80G/2019-20/1026046462(1)</span> <br>Trust PAN Number. <span>AACTV6878K</span></h3>
          </div>
      </div>

    </div>
  </section> -->
  <!-- End Cta Section -->


  <marquee direction="scroll" onmouseover="this.stop();" onmouseout="this.start();">
    <a routerLink="/download" target="blank"><img src="assets/img/new.gif">* Download Your 10 BE Latest Receipt.</a>
  </marquee>


  <section class="about_section layout_padding layout_margin-top layout_margin-bottom">
    <div class="container  ">
      <div class="row">
        <div class="col-md-6 ">
          <div class="img-box">
            <img src="assets/img/donateimg.jpeg" alt="">
          </div>
        </div>
        <div class="col-md-6">
          <div class="about-content">
            <h3 align="center" class="news">
              Latest News about vinyasa trust
            </h3>
            <marquee behavior="scroll" direction="up" height="400" scrollamount="2"
            style="color:#574741; background:#dcdcdc;" onmouseover="this.stop();" onmouseout="this.start();">
            <div *ngFor="let g of this.cmsdata.latestnews">
              <a href="" target="blank" style="color: #574741;"><img src="assets/img/new.gif">{{g.heading}}</a><br>
            </div>
          </marquee>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="cta" class="cta1">
    <div class="container" data-aos="zoom-in">

      <div class="text-center">
        <h3>Donations are exempt from tax as per
          <span>section 80G & 12A</span> of Indian Income Tax Act</h3>
          <h3>80G Order Number. ITBA/EXM/S/80G/2019-20/1026046462(1)
          </h3>
          <h3>Trust Pan Number. AACTV6878K
          </h3>
      </div>

    </div>
  </section>

  <!-- #about



   <a href="" target="blank" style="color: #574741;"><img src="assets/img/new.gif">* All the donation is subjected to 80G certificate tax exemption.</a><br>
            <a href="" target="blank" style="color: #574741;"><img src="assets/img/new.gif">* Support our project swachhamaya</a><br>
            <a href="" style="color: #574741;"><img src="assets/img/new.gif">* Under the program vidya vinyasa we are requesting sponsorship for the student from the donor.</a>
  -->

  <!-- ======= Cta Pricing Section ======= -->
  <!-- <section id="cta-pricing" class="cta-pricing">
    <div class="container">

      <div class="text-center">
        <h3>Latest Activities at Premises of Vinyasa Trust for Differently Challenged</h3>
         <a class="cta-btn" routerLink="/activities" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">Click to Know </a>
      </div>

    </div>
  </section> -->

  <!-- ======= Services Section ======= -->


  <!-- <section id="blog" class="blog">
    <div class="container">
           <h3 style="text-align: center;">Our Programs</h3>
           <br>
      <div class="row">

        <div class="col-lg-3  col-md-6 d-flex align-items-stretch" data-aos="fade-up">
          <article class="entry">

            <div class="entry-img">
              <img src="assets/img/Education21.jpeg" alt="" class="img-fluid">
            </div>

            <h2 class="entry-title">
              <a routerLink="/education" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">Vidya Vinyasa</a>
            </h2>


            <div class="entry-content">
              <p style="text-align:center">
                Vinyasa provides students with a gift they will cherish for life education. From providing dedicated teachers to taking care of the expenditure, we give end-to-end support to help these bright minds realise their dreams.

              </p>

            </div>

          </article>
        </div>

        <div class="col-lg-3  col-md-6 d-flex align-items-stretch" data-aos="fade-up">
          <article class="entry">

            <div class="entry-img">
              <img src="assets/img/kala1.jpeg" alt="" class="img-fluid">
            </div>


            <h2 class="entry-title">
              <a routerLink="/kalavinyasa" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">Kala Vinyasa</a>
            </h2>


            <div class="entry-content">
              <p style="text-align:center">
                Kala Vinyasa is designed to bring a smile on the faces of these lovely children by providing a stage to showcase their artistic side through various activities at the national and international levels.                </p>

            </div>

          </article>
        </div>

        <div class="col-lg-3  col-md-6 d-flex align-items-stretch" data-aos="fade-up">
          <article class="entry">

            <div class="entry-img">
              <img src="assets/img/IDFL1.jpeg" alt="" class="img-fluid">
            </div>


            <h2 class="entry-title">
              <a routerLink="/sports" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">Kreeda Vinyasa (Sports)</a>
            </h2>


            <div class="entry-content">
              <p style="text-align:center">
                Sports is joy. Sports is freedom. Kreeda Vinyasa is all about bridging the gap between talent and opportunity by providing students a platform to express themselves through sports.</p>

            </div>

          </article>
        </div>

        <div class="col-lg-3  col-md-6 d-flex align-items-stretch" data-aos="fade-up">
          <article class="entry">

            <div class="entry-img">
              <img src="assets/img/about1234.jpeg" alt="" class="img-fluid">
            </div>


            <h2 class="entry-title">
              <a routerLink="/accomodation" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">Vasati Vinyasa</a>
            </h2>


            <div class="entry-content">
              <p style="text-align:center">
                Happiness begins with H and it’s no surprise that Hygiene and Health do too. Vinyasa promotes the well-being of every student by ensuring hygiene within the premises and a healthy food regime.                </p>
            </div>

          </article>
        </div>

      </div>

    </div>
  </section> -->

  <section id="ts-service-area" class="ts-service-area pb-0">
    <div class="container">
      <div class="row text-center">
          <div class="col-12">
            <h2 class="section-title" style="color: #ffb600;"> Our Work</h2>

          </div>
      </div>

      <div class="row">
          <div class="col-lg-4">
            <div class="ts-service-box d-flex">
                <div class="ts-service-box-img">
                  <img loading="lazy" src="assets/img/Education21.jpeg" alt="service-icon" height="90" width="90" style="border-radius: 50%;">
                </div>
                <div class="ts-service-box-info">
                  <h3 class="service-box-title">
                    <a routerLink="/education" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">Vidya Vinyasa</a>
                  </h3>
                  <p style="text-align: justify;">Vinyasa provides students with a gift they will cherish for life-- education. From providing dedicated
                    teachers to taking care of the expenditure, we give end-to-end support
                     to help these bright minds realise their dreams.</p>
                </div>
            </div>

            <div class="ts-service-box d-flex">
              <div class="ts-service-box-img">
                <img loading="lazy" src="assets/img/about1234.jpeg" alt="service-icon" height="90" width="90" style="border-radius: 50%;">
              </div>
              <div class="ts-service-box-info">
                <h3 class="service-box-title">
                  <a routerLink="/accomodation" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">Vasati Vinyasa</a>
                </h3>
                <p style="text-align: justify;">Happiness begins with H and it’s no surprise that Hygiene and Health do too. Vinyasa promotes the well-being of every student
                  by ensuring hygiene within the premises and a healthy food regime.</p>
              </div>
          </div>



          </div>

          <div class="col-lg-4 text-center">
            <img loading="lazy" class="img-fluid" src="assets/img/abdul11.jpeg" alt="service-avater-image">
          </div>

          <div class="col-lg-4 mt-5 mt-lg-0 mb-4 mb-lg-0">
            <div class="ts-service-box d-flex">
                <div class="ts-service-box-img">
                  <img loading="lazy" src="assets/img/IDFL1.jpeg" alt="service-icon" height="90" width="90" style="border-radius: 50%;">
                </div>
                <div class="ts-service-box-info">
                  <h3 class="service-box-title">
                    <a routerLink="/sports" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">Kreeda Vinyasa (Sports)</a>
                  </h3>
                  <p style="text-align: justify;">Sports is joy. Sports is freedom. Kreeda Vinyasa is all about bridging the gap between talent and opportunity by
                    providing students a platform to express themselves through sports.</p>
                </div>
            </div>

            <div class="ts-service-box d-flex">
              <div class="ts-service-box-img">
                <img loading="lazy" src="assets/img/kala1.jpeg" alt="service-icon" height="90" width="90" style="border-radius: 50%;">
              </div>
              <div class="ts-service-box-info">
                <h3 class="service-box-title">
                  <a routerLink="/kalavinyasa" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">Kala Vinyasa</a>
                </h3>
                <p style="text-align: justify;">Kala Vinyasa is designed to bring a smile on the faces of these lovely children by providing a stage to
                   showcase their artistic side through
                  various activities at the national and international levels.</p>
              </div>
          </div>

          </div>
      </div>

    </div>
  </section><!-- Service end -->

  <!-- End Services Section -->

  <!-- ======= Cta Pricing Section ======= -->
  <!-- <section id="cta-pricing" class="cta-pricing">
    <div class="container">

      <div class="text-center">
        <h3>Show Donor</h3>
         <a class="cta-btn" routerLink="/donorlist" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">Click to Know </a>
      </div>

    </div>
  </section> -->

  <!-- End Cta Pricing Section -->

  <!-- ======= About Video Section ======= -->
  <section id="about-video" class="about-video">
    <div class="container" data-aos="fade-up">
      <div class="row">
        <div class="col-lg-6 video-box align-self-baseline" data-aos="fade-right" data-aos-delay="100">
          <img src="assets/img/s1.jpg" class="img-fluid" alt="SWACHHAMAYA">
          <!-- <a href="" class="venobox play-btn mb-4" data-vbtype="video" data-autoplay="true"></a> -->
        </div>

        <div class="col-lg-6 pt-3 pt-lg-0 content" data-aos="fade-left" data-aos-delay="100">
          <h3 style="text-align: center;"> <strong style="color: green;"> " Swachhamaya Program</strong></h3>
          <h3 style="text-align: center;"> <strong style="color: green;"> From Waste to Value "</strong></h3>
          <br>
          <p class="font-italic" style="text-align: justify;">
            SWACHHAMAYA has been working towards reducing the burden on the landfills by recycling. It is contributing to the circular economy by providing education/livelihood opportunities to the people with disabilities & underprivileged.
          </p>
          <p style="text-align: justify;">
           <strong style="color: green;"> Waste To Value:</strong> If we have to save this planet for our future generations, recycling is the way to go. It helps reduce pollution caused by waste and maintain a clean environment.
          </p>

          <div style="text-align: center; margin-top: 25px;">
            <a routerLink="/swachhamaya" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"><button type="button" class="btn btn-sm nbtn">Read More!</button></a>
          </div>
        </div>
      </div>

    </div>
  </section>
  <!-- End About Video Section -->
  </main>

  <!-- End #main -->
