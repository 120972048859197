import { Component, OnInit } from '@angular/core';
import { VinyasaService } from '../vinyasa.service';
import { HttpClient,HttpHeaders } from '@angular/common/http';



@Component({
  selector: 'app-downloadform',
  templateUrl: './downloadform.component.html',
  styleUrls: ['./downloadform.component.css']
})
export class DownloadformComponent implements OnInit {

  name: string;
pan: string;

  constructor(public cmsdata: VinyasaService,private http: HttpClient) {

  }

  ngOnInit(): void {

  }
  submit(){
    // console.log(this.name);
    // this.cmsdata.filldownloaddata(this.name,this.pan).then((data) => {
    //   console.log(this.cmsdata.downloadform);
    // })
    // .catch((error) => {
    // });

    this.downloadFile(this.name,this.pan);

  }

//   downloadFile1(fileUrl: string): Promise<Blob> {
//     const headers = new Headers();
//         headers.append('Access-Control-Allow-Headers', 'Access-Control-Allow-Headers: Origin, Content-Type, X-Auth-Token');
//         headers.append('Access-Control-Allow-Methods', '*');
//         headers.append('Access-Control-Allow-Origin', '*');
//         const headersObject = {};
//         headers.forEach((value, key) => {
//             headersObject[key] = value;
//         });

// const httpHeaders = new HttpHeaders(headersObject);
//     return this.http.get(fileUrl, {headers: httpHeaders, responseType: 'blob' }).toPromise();
//   }

  downloadFile(name,pan) {

    this.cmsdata.getDownloadData().subscribe(
      (list) => {
        this.cmsdata.downloaddatas = list.map((item) => {
          return {
            $key: item.key,
            ...item.payload.val()
          };
        });

        this.cmsdata.downloadform = this.cmsdata.downloaddatas.filter(x => x.name === name && x.pan===pan && x.active === true);
       if(this.cmsdata.downloadform.length>0){
        this.cmsdata.downloadform.forEach(x=>{
          const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = (event) => {
          const blob = xhr.response;
          //console.log(blob);
          const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = pan+".pdf"; // Specify the downloaded file name
            link.click();
            window.URL.revokeObjectURL(url); // Free up memory
        };
        xhr.open('GET', x.file);
        xhr.send();
        });
       this.name="";
       this.pan="";
       alert("Found "+this.cmsdata.downloadform.length+" file(s), Downloading Started...");
       }else{
        alert("Sorry...No Such File.Please provide valid details.")
       }

      });
    // this.downloadFile1(fileUrl)
    //   .then((blob) => {
    //     const url = window.URL.createObjectURL(blob);
    //     console.log(url)

    //   })
    //   .catch((error) => {
    //     console.error('Download error:', error);
    //   });

       // This can be downloaded directly:

  }

}
