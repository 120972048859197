import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { HeadingComponent } from './heading/heading.component';
import { NavigationComponent } from './navigation/navigation.component';
import { HomeComponent } from './home/home.component';
import { ContactComponent } from './contact/contact.component';
import { FooterComponent } from './footer/footer.component';
import { SidemenuComponent } from './sidemenu/sidemenu.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { EducationComponent } from './education/education.component';
import { KalavinyasaComponent } from './kalavinyasa/kalavinyasa.component';
import { SportsComponent } from './sports/sports.component';
import { AccomodationComponent } from './accomodation/accomodation.component';
import { GalleryComponent } from './gallery/gallery.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { NewsComponent } from './news/news.component';
import { DonateComponent } from './donate/donate.component';
import { SwachhamayaComponent } from './swachhamaya/swachhamaya.component';
import { DonorlistComponent } from './donorlist/donorlist.component';
import { AngularFireModule } from 'angularfire2';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { environment } from 'src/environments/environment';
import { AngularFireStorageModule } from 'angularfire2/storage';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ActivitiesComponent } from './activities/activities.component';
import { CorporateComponent } from './corporate/corporate.component';
import { StudentComponent } from './student/student.component';
import { ClientfeedbackComponent } from './clientfeedback/clientfeedback.component';
import { TermconditionComponent } from './termcondition/termcondition.component';
import { PrivacypolicyComponent } from './privacypolicy/privacypolicy.component';
import { RefundandcancellationComponent } from './refundandcancellation/refundandcancellation.component';
import { DownloadformComponent } from './downloadform/downloadform.component';

const routes: Routes = [

  { path: 'home', component: HomeComponent },
   { path: 'contact', component: ContactComponent },
   { path: 'aboutus', component: AboutusComponent },
   { path: 'education', component: EducationComponent },
   { path: 'kalavinyasa', component: KalavinyasaComponent },
   { path: 'sports', component: SportsComponent },
   { path: 'accomodation', component: AccomodationComponent },
   { path: 'gallary', component: GalleryComponent },
   { path: 'feedback', component: FeedbackComponent },
   { path: 'termsandconditions', component: TermconditionComponent },
   { path: 'privacypolicy', component: PrivacypolicyComponent },
   { path: 'refundandcancellation', component: RefundandcancellationComponent },
   { path: 'clientfeedback', component: ClientfeedbackComponent },
   { path: 'news', component: NewsComponent },
   { path: 'donate', component: DonateComponent },
   { path: 'swachhamaya', component: SwachhamayaComponent },
   { path: 'donorlist', component: DonorlistComponent },
   { path: 'activities', component: ActivitiesComponent },
   { path: 'corporate', component: CorporateComponent },
   { path: 'student', component: StudentComponent },
   { path: 'download', component: DownloadformComponent },
  { path: '',   redirectTo: '/home', pathMatch: 'full' }, // redirect to `first-component`
  { path: '**', component: HomeComponent }
];

@NgModule({
  declarations: [
    AppComponent,
    HeadingComponent,
    NavigationComponent,
    HomeComponent,
    ContactComponent,
    FooterComponent,
    SidemenuComponent,
    AboutusComponent,
    EducationComponent,
    KalavinyasaComponent,
    SportsComponent,
    AccomodationComponent,
    GalleryComponent,
    FeedbackComponent,
    NewsComponent,
    DonateComponent,
    SwachhamayaComponent,
    DonorlistComponent,
    ActivitiesComponent,
    CorporateComponent,
    StudentComponent,
    ClientfeedbackComponent,
    TermconditionComponent,
    PrivacypolicyComponent,
    RefundandcancellationComponent,
    DownloadformComponent
  ],
  imports: [
    BrowserModule,
    [RouterModule.forRoot(routes)],
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireDatabaseModule,
    AngularFireStorageModule,
    FormsModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
